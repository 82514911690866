import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import GlobalContext from '../../context/global-context';
import Anchors from '../global/anchors/anchors';
import CustomToast from '../global/customToast/customToast';

import ContactSection from './contactSection';
import FaqCta from './faqCta';

import './contactUs.scss';

const ContactUs: React.FC<{ helpPath: string }> = ({ helpPath }) => {
  const { i18n } = useContext(GlobalContext);

  const [contactFormSuccess, setContactFormSuccess] = useState<boolean | null>(
    null
  );

  const titles = [i18n?.t('contact_us.contact_section.title')];
  return (
    <Row>
      <Col lg={4} xl={3} className="sticky">
        <div>
          <Anchors titles={titles} defaultText={i18n?.t('contact_us.title')} />
          <div className="side-faq">
            {helpPath && <FaqCta helpPath={helpPath} />}
          </div>
        </div>
      </Col>
      <Col lg={1} xl={1} />
      <Col lg={7} xl={8} className="gx-4">
        <ContactSection
          setFormSuccess={setContactFormSuccess}
          helpPath={helpPath}
        />
      </Col>

      <CustomToast toast={contactFormSuccess} setToast={setContactFormSuccess}>
        <span>{i18n?.t('register_contact.contact_form.success')}</span>
      </CustomToast>
    </Row>
  );
};

export default ContactUs;
