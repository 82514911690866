import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import GlobalContext from '../../context/global-context';
import { useLocaleFromRouter } from '../../hooks/useLocaleFromRouter';
import { sendEmail } from '../../utils/emails';
import { LocaleType } from '../../utils/i18n';
import CustomButton, {
  bsButtonTypes,
  buttonOptions,
} from '../global/customButton/customButton';
import Loading from '../global/loading/loading';

const ContactForm: React.FC<{
  setFormSuccess: React.Dispatch<React.SetStateAction<boolean | null>>;
}> = ({ setFormSuccess }) => {
  const { i18n } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  const [emailSentError, setEmailSentError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(i18n?.t('form.errors.invalid_email'))
      .required(i18n?.t('form.errors.required_email')),
  });

  type ContactFormValues = {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    message: string;
  };

  const initialValues: ContactFormValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
  };

  const handleSubmit =
    (values: ContactFormValues) =>
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();

      // Already submitting
      if (submitting) {
        return;
      }

      setEmailSentError(false);
      setSubmitting(true);

      const success = await sendEmail('contact', values, currentLocale);

      setSubmitting(false);
      setEmailSentError(!success);
      if (success) setFormSuccess(true);
    };

  return (
    <div className="form-section">
      <h4>{i18n?.t('register_contact.contact_form.title')}</h4>
      <Formik
        validationSchema={schema}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => {}}
        initialValues={initialValues}
      >
        {({ handleChange, handleBlur, values, errors, touched }) => (
          <Form noValidate onSubmit={handleSubmit(values)} id="contact-form">
            <Row>
              <Col sm={6}>
                <FloatingLabel
                  controlId="validationFormikFirstname"
                  label={i18n?.t('form.labels.firstname')}
                >
                  <Form.Control
                    type="text"
                    placeholder={i18n?.t('form.labels.firstname')}
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6}>
                <FloatingLabel
                  controlId="validationFormikLastname"
                  label={i18n?.t('form.labels.lastname')}
                >
                  <Form.Control
                    type="text"
                    placeholder={i18n?.t('form.labels.lastname')}
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <FloatingLabel
              controlId="validationFormikEmail"
              label={`${i18n?.t('form.labels.email')}*`}
            >
              <Form.Control
                type="email"
                placeholder={`${i18n?.t('form.labels.email')}*`}
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email ? !!errors.email : false}
              />
              {touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="validationFormikPhone"
              label={i18n?.t('form.labels.phone')}
            >
              <Form.Control
                type="tel"
                placeholder={i18n?.t('form.labels.phone')}
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="validationFormikMessage"
              label={i18n?.t('form.labels.message')}
            >
              <Form.Control
                type="text"
                placeholder={i18n?.t('form.labels.message')}
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FloatingLabel>
            <CustomButton
              type={bsButtonTypes.submit}
              style={buttonOptions.filled_light}
              className="cta"
              disabled={
                Object.keys(touched).length === 0 ||
                Object.keys(errors).length > 0
              }
            >
              {submitting && <Loading type="btn" />}
              {!submitting && i18n?.t('register_contact.register_form.submit')}
            </CustomButton>
            {emailSentError && (
              <div className="form-error-container">
                {i18n?.t('register_contact.contact_form.error')}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
