import React from 'react';

import ContactUs from '../components/contactUs/contactUs';
import Banner, { pageType } from '../components/global/banner/banner';
import Layout from '../components/global/layout/layout';
import { PageBase } from '../context/global-context';
import { LocalizationData } from '../types/general';
import { PageContext, PageData } from '../types/page';
import { getLocales, LocaleEnum, useI18n } from '../utils/i18n';

type ContactUsContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
  helpPath: string;
};

const ContactUsPage: React.FC<{
  pageContext: ContactUsContext;
}> = ({ pageContext }) => {
  const { translations, locale, slug, helpPath } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: LocalizationData[] = [];

  locales.forEach((l) => {
    const slug = i18n.t('contact_us.slug', l);

    localizationsData.push({
      attributes: {
        slug: slug ? slug : 'contact-us',
        locale: l,
      },
    });
  });

  const instance: PageData = {
    attributes: {
      slug: slug,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <PageBase translations={translations} instance={instance}>
      <Layout>
        <Banner
          title={i18n.t('contact_us.title')}
          bannerType="contact-us-page"
          pageType={pageType.page}
        />
        <main className="contact-us">
          <ContactUs helpPath={helpPath} />
        </main>
      </Layout>
    </PageBase>
  );
};

export default ContactUsPage;
