import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import slugify from 'slugify';

import GlobalContext from '../../context/global-context';
import { phoneLink } from '../../utils/formatStrings';

import ContactForm from './contactForm';
import FaqCta from './faqCta';

const ContactSection: React.FC<{
  setFormSuccess: React.Dispatch<React.SetStateAction<boolean | null>>;
  helpPath: string;
}> = ({ setFormSuccess, helpPath }) => {
  const { i18n } = useContext(GlobalContext);

  if (typeof i18n === 'undefined') return null;
  const id = slugify(i18n.t('contact_us.contact_section.title').toLowerCase());
  return (
    <div className="contact-section" id={id}>
      <h2>{i18n.t('contact_us.contact_section.title')}</h2>
      <p className="description">
        {i18n.t('register_contact.contact_section.text')}
      </p>
      <FaqCta helpPath={helpPath} />

      <Row className="contact-info">
        <Col sm={6} className="address">
          <h4>
            {i18n.t('register_contact.contact_section.mailing_address.title')}
          </h4>
          <div>{i18n.t('register_contact.contact_info.title')}</div>
          <div>{i18n.t('register_contact.contact_info.street')}</div>
          <div>{i18n.t('register_contact.contact_info.suite')}</div>
          <div>{i18n.t('register_contact.contact_info.city')}</div>
          <div>{i18n.t('register_contact.contact_info.postal_code')}</div>
        </Col>
        <Col sm={6}>
          <h4>{i18n.t('register_contact.contact_section.telephone.title')}</h4>
          <p>
            <a
              className="phone-number"
              href={phoneLink(
                i18n.t('register_contact.contact_section.telephone')
              )}
            >
              {i18n?.t('register_contact.contact_section.telephone')}
            </a>
          </p>
        </Col>
      </Row>
      <ContactForm setFormSuccess={setFormSuccess} />
    </div>
  );
};

export default ContactSection;
