import React, { useState } from 'react';
import ScrollSpy from 'react-scrollspy-navigation';
import slugify from 'slugify';

import { isBrowser } from '../../../utils/browser';

import './anchors.scss';

const Anchors: React.FC<{
  titles: (string | undefined)[];
  defaultText: string | undefined;
}> = ({ titles, defaultText }) => {
  // Match lg breakpoint
  const isMobile = isBrowser && window.innerWidth >= 1025 ? false : true;
  const offsetTop = isMobile ? 145 : 40;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className={isOpen && isMobile ? 'open anchors' : 'anchors'}
        onClick={() => {
          setIsOpen((prevIsOpen) => !prevIsOpen);
        }}
      >
        <ScrollSpy duration={50} offsetTop={offsetTop}>
          {titles.map((title, i) => {
            const anchorHref = title
              ? slugify(title.toLowerCase().replace("'", ''))
              : '';
            return (
              <a
                href={`#${anchorHref}`}
                key={`${title}-${i}`}
                ref={React.createRef()}
              >
                {title}
              </a>
            );
          })}
        </ScrollSpy>
        <a className="default">{defaultText}</a>
      </div>
    </>
  );
};

export default Anchors;
