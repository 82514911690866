import { navigate } from 'gatsby';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import GlobalContext from '../../context/global-context';
import { ReactComponent as CircleRightSVG } from '../../images/circle_right.svg';

const FaqCta: React.FC<{ helpPath: string }> = ({ helpPath }) => {
  const { i18n } = useContext(GlobalContext);

  const handleClick = () => {
    navigate(helpPath);
  };

  return (
    <div className="faq-cta" onClick={handleClick}>
      <div>
        <h5>{i18n?.t('register_contact.faq_cta.title')}</h5>
        <Row>
          <Col>
            <p>{i18n?.t('register_contact.faq_cta.text')}</p>
          </Col>
          <Col xs={2} md={2} className="arrow">
            <CircleRightSVG className="float-end" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FaqCta;
